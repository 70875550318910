import React from 'react';
import { DealerRestrictionDTO, WeekDetailDTO } from '@ovex/pvt-web-api';
import PropTypes from 'prop-types';

import { OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';

import AvailableCapacityTableData from './AvailableCapacityTableData';

import './AvailableCapacityTable.scss';

const propTypes = {
  restrictionList: PropTypes.arrayOf(PropTypes.instanceOf(DealerRestrictionDTO)),
  weekDetailList: PropTypes.arrayOf(PropTypes.instanceOf(WeekDetailDTO))
};

const defaultProps = {
  restrictionList: null,
  weekDetailList: null
};
function AvailableCapacityTable(props) {
  const lsi = React.useContext(LsiContext);

  const _columns = () => {
    const restrictionColumns = AvailableCapacityTableData.restrictionColumns(lsi);
    const restrictionWeekColumns = AvailableCapacityTableData.restrictionWeekColumns(props.weekDetailList, lsi);
    return [...restrictionColumns, ...restrictionWeekColumns];
  };

  const rowData = AvailableCapacityTableData.transformToRestrictionRows(props.restrictionList, lsi);

  return (
    <OvexAGTable
      className={'ovex-AvailableCapacityTable'}
      columnDefs={_columns()}
      columnTypes={AvailableCapacityTableData.columnTypes}
      enableRangeSelection
      getContextMenuItems={AvailableCapacityTableData.getContextMenuItems}
      getRowHeight={AvailableCapacityTableData.getRowHeight}
      height="540px"
      rowClassRules={AvailableCapacityTableData.rowClassRules}
      rowData={rowData}
      suppressRowTransform
    />
  );
}

AvailableCapacityTable.propTypes = propTypes;
AvailableCapacityTable.defaultProps = defaultProps;

export default AvailableCapacityTable;