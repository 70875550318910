import React from 'react';
import PropTypes from 'prop-types';
import 'uu5g04/bricks';
import 'uu5g04/forms';
import { PGAccountingDTO } from '@ovex/price-guarantee-web-api';

import { LsiContext } from '../../../../../common/contexts';
import { OvexAGTable } from '../../../../../common/components/ag-grid';
import PriceGuaranteeTabs from '../PriceGuaranteeTabs';
import { isReadOnlyInput } from '../PriceGuaranteeEditInputRules';

import { columnTypes, getColumnDefs } from './PriceGuaranteeCreationModel.columnDef';

const propTypes = {
  editRules: PropTypes.arrayOf(PropTypes.string).isRequired,
  marketingActionCode: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  pgAccountingList: PropTypes.arrayOf(PGAccountingDTO),
  rowData: PropTypes.array
};

const defaultProps = {
  marketingActionCode: null,
  pgAccountingList: null,
  rowData: null
};

export const InputNames = {
  accountingBasisList: 'accountingBasisList'
};

const PriceGuaranteeFormAccountingComponent = (props, ref) => {
  const lsi = React.useContext(LsiContext);

  const readOnly = isReadOnlyInput(props.editRules, InputNames.accountingBasisList, !props.onSave);

  const tableRef = React.useRef(null);
  const [selectedRows, setSelectedRows] = React.useState(null);
  const [rows, setRows] = React.useState([]);

  React.useEffect(
    () => {
      const mergedRows = [...new Set([...props.rowData ?? [], ...props.pgAccountingList ?? []])];
      let rowsForTable = [];
      mergedRows.forEach(row => {
        if (!rowsForTable.find(accb => accb.accountingCode === row.accountingCode && accb.packageCode === row.packageCode )) {
          //setRows([...rows, row]);
          rowsForTable = [...rowsForTable, row];

        }
      });
      rowsForTable.sort((a, b) => a.year - b.year || b.packageType.localeCompare(a.packageType));
      setRows(rowsForTable);
    }, [props.pgAccountingList, props.rowData]);

  React.useImperativeHandle(
    ref,
    () => ({
      isValid: () => true,
      save: () => {
        props.onSave && props.onSave({ formId: PriceGuaranteeTabs.pgAccountingList, values: { pgAccountingList: selectedRows } });
      }
    })
  );

  const columnDefs = React.useMemo(
    () => getColumnDefs(lsi, readOnly),
    [lsi, readOnly]
  );

  const handleIsPgAccountingAssigned = (params) => {
    return (params.data.assignedPG === props.marketingActionCode && params.data.assignedPG) || !params.data.assignedPG;
  };

  const handleAssignedPGRowClass = (params) => handleIsPgAccountingAssigned(params) ? '' : 'ovex-pg-PriceGuaranteeCreationModalForm-assigned-pg';

  return (

    <OvexAGTable
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      getRowClass={handleAssignedPGRowClass}
      height='386px'
      isRowSelectable={handleIsPgAccountingAssigned}
      onRowDataUpdated={(params) => {
        params.api.forEachNode((node) => {
          const data = node.data;
          const selected = data.assignedPG && props.marketingActionCode === data.assignedPG;
          node.setSelected(!!selected);
        });
      }}
      onRowSelected={(props) => setSelectedRows(props.api.getSelectedRows())}
      ref={tableRef}
      rowData={rows}
      rowSelection='multiple'
      suppressRowClickSelection
    />
  );
};
const PriceGuaranteeFormAccounting = React.memo(React.forwardRef(PriceGuaranteeFormAccountingComponent));

PriceGuaranteeFormAccounting.propTypes = propTypes;
PriceGuaranteeFormAccounting.defaultProps = defaultProps;

export default PriceGuaranteeFormAccounting;
