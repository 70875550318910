import React from 'react';
import PropTypes from 'prop-types';
import {
  CommissionSpecificationDTOVLD,
  ConditionCiColumnDefinitionDTOVLD,
  COrderHeaderAccountWithPGItemsDTOVLD
} from '@ovex/price-guarantee-web-api';

import { CustomHeaderLabel, GROUP_DISPLAY_TYPE, OvexAGTable } from '../../../../../common/components/ag-grid';
import { LsiContext } from '../../../../../common/contexts';

import { columnTypes, getAutoGroupColumnDef, getColumnDefinitionsData } from './ValidationResultPGItemTable.columnDefs';
import { handleGetContextMenuItems } from './ValidationResultPGItemTable.contextMenu';
import { calculateSumAmounts } from './ValidationResultPGItemTable.helpers';
import { getRowFromCommission } from './ValidationResultPGItemTable.pinnedRowData';
import { prepareRowData } from './ValidationResultPGItemTable.rowData';

import './ValidationResultPGItemTable.scss';

const propTypes = {
  cOrderHeaderAccountList: PropTypes.arrayOf(PropTypes.instanceOf(COrderHeaderAccountWithPGItemsDTOVLD)).isRequired,
  commission: PropTypes.instanceOf(CommissionSpecificationDTOVLD).isRequired,
  conditionCiColumnDefinitionList: PropTypes.arrayOf(PropTypes.instanceOf(ConditionCiColumnDefinitionDTOVLD)).isRequired,
  currency: PropTypes.string.isRequired
};

const defaultProps = {
};

const ValidationResultPGItemTable = (props) => {
  const lsi = React.useContext(LsiContext);
  const { commission, currency, cOrderHeaderAccountList, conditionCiColumnDefinitionList } = props;

  const columnDefinitionsData = React.useMemo(
    () => getColumnDefinitionsData(conditionCiColumnDefinitionList, lsi, currency),
    [conditionCiColumnDefinitionList, lsi, currency]
  );
  const specSnapshotCUIDList = columnDefinitionsData.specSnapshotCUIDList;

  const sumAmounts = React.useMemo(
    () => calculateSumAmounts(cOrderHeaderAccountList),
    [cOrderHeaderAccountList]
  );

  const pgItemRowFromCommissionList = React.useMemo(
    () => (getRowFromCommission(commission, sumAmounts, specSnapshotCUIDList)),
    [commission, sumAmounts, specSnapshotCUIDList]
  );

  const pgItemRowList = React.useMemo(
    () => {
      return prepareRowData(cOrderHeaderAccountList, specSnapshotCUIDList);
    },
    [cOrderHeaderAccountList, specSnapshotCUIDList]
  );

  return (
    <OvexAGTable
      autoGroupColumnDef={getAutoGroupColumnDef(lsi)}
      className='ovex-pg-ValidationResultPGItemTable'
      columnDefs={columnDefinitionsData.columnDefinitionList}
      columnTypes={columnTypes}
      components={{
        customHeaderLabel: CustomHeaderLabel
      }}
      domLayout='autoHeight'
      enableRangeSelection
      getContextMenuItems={handleGetContextMenuItems}
      groupDefaultExpanded={1}
      groupDisplayType={GROUP_DISPLAY_TYPE.singleColumn}
      pinnedTopRowData={pgItemRowFromCommissionList}
      rowData={pgItemRowList}
    />
  );
};

ValidationResultPGItemTable.propTypes = propTypes;
ValidationResultPGItemTable.defaultProps = defaultProps;

export default ValidationResultPGItemTable;
