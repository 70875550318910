import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { isString } from '../../../../../common/utils/helpers';
import { EXCEL_STYLES_IDS, OvexAGTable } from '../../../../../common/components/ag-grid';
import { LsiContext } from '../../../../../common/contexts';
import { percentToDecimal } from '../../../../utils/helpers';
import { decimalToPercentFormatter } from '../../../../utils/formatters';
import { percentValue0to100Parser } from '../../../../../common/utils/parsers';

const mgWithMixRatioMapper = (mg) => ({
  selected: false,
  modelGroup: mg.modelGroup,
  modelGroupName: mg.modelGroupName,
  modelMixRatio: mg.modelMixRatio != null ? mg.modelMixRatio : 0,
  isInNegotiation: mg.isInNegotiation != null ? mg.isInNegotiation : false
});

const YearSettingsModelGroupsComponent = (props, ref) => {
  const lsi = React.useContext(LsiContext);
  const gridApi = useRef(null);
  const readOnly = !props.onSave;

  const classNames = ['ovex-YearSettingsModelGroups'];
  props.className && classNames.push(props.className);

  const modelGroupAvailableList = React.useMemo(
    () => {
      const mgaList = props.modelGroupList ? props.modelGroupList.map(mgWithMixRatioMapper) : [];

      props.yearSettingsModelGroupList && props.yearSettingsModelGroupList.forEach(mg => {
        const modelGroup = mgaList.find(mga => mga.modelGroup === mg.modelGroup);
        if (modelGroup) {
          modelGroup.selected = true;
          modelGroup.modelMixRatio = mg.modelMixRatio;
          modelGroup.isInNegotiation = mg.isInNegotiation;
        } else {
          const mga = mgWithMixRatioMapper(mg);
          mga.selected = true;
          mgaList.push(mga);
        }
      });

      return mgaList;
    },
    [props.modelGroupList, props.yearSettingsModelGroupList]
  );

  const [ modelGroupsAvailable, setModelGroupsAvailable ] = React.useState(modelGroupAvailableList);
  React.useEffect(
    () => setModelGroupsAvailable(modelGroupAvailableList),
    [modelGroupAvailableList]
  );

  React.useImperativeHandle(
    ref,
    () => ({
      isValid: () => {
        return true;
      },
      save: () => {
        props.onSave && props.onSave({ formId: props.formId, values: gridApi.current.getSelectedRows() });
      }
    })
  );

  const getColumnDefs = () => {
    const handleModelMixRatioValueFormatter = (valueFormatterParams) => {
      return decimalToPercentFormatter(valueFormatterParams.value, 2);
    };

    const modelGroupColumnDef = { colId: 'modelGroup', valueGetter: 'data.modelGroup', headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL'), width: 100, type: ['sortableFilterDefinitionColumn'], cellClass: EXCEL_STYLES_IDS.STRING };
    const modelGroupNameColumnDef = { colId: 'modelGroupName', valueGetter: 'data.modelGroupName', headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL_NAME'), type: ['sortableFilterDefinitionColumn'], flex: 1 };
    const modelMixRatioColumnDef = { colId: 'modelMixRatio', headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL_MIX_RATIO_ABBR'), headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.MODEL_MIX_RATIO'), width: 100, valueGetter: 'data.modelMixRatio', valueFormatter: handleModelMixRatioValueFormatter };

    if (readOnly) {
      return [modelGroupColumnDef, modelGroupNameColumnDef, modelMixRatioColumnDef];
    }

    const handleModelMixRatioEditable = (opt) => {
      return !readOnly && opt.node.selected;
    };
    const handleModelMixRatioValueParser = (valueParserParams) => {
      return percentValue0to100Parser(valueParserParams.newValue);
    };
    const handleModelMixRatioValueSetter = (valueSetterParams) => {
      if (isString(valueSetterParams.newValue)) {
        valueSetterParams.newValue = handleModelMixRatioValueParser(valueSetterParams);
      }
      if (valueSetterParams.newValue != null && valueSetterParams.newValue !== valueSetterParams.oldValue) {
        valueSetterParams.data.modelMixRatio = percentToDecimal(valueSetterParams.newValue, 4);
        return true;
      }
      return false;
    };

    return [
      { colId: 'checkBox', valueGetter: 'data.checkBox', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, width: 40, menuTabs: [] },
      modelGroupColumnDef,
      modelGroupNameColumnDef,
      { ...modelMixRatioColumnDef, editable: handleModelMixRatioEditable, valueParser: handleModelMixRatioValueParser, valueSetter: handleModelMixRatioValueSetter }
    ];
  };

  const handleRowSelected = (rowSelectedParams) => {
    if (rowSelectedParams.data.isInNegotiation) {
      rowSelectedParams.node.setSelected(true);
    } else {
      const selected = rowSelectedParams.node.selected;
      rowSelectedParams.node.setSelected(selected);
      rowSelectedParams.data.selected = selected;
      if (!selected) {
        rowSelectedParams.data.modelMixRatio = 0;
        rowSelectedParams.api.refreshCells({ rowNodes: [rowSelectedParams.node], columns: ['modelMixRatio'], force: true });
      }
    }
  };

  return (
    <Bricks.Div
      className={classNames.join(' ')}
    >
      <OvexAGTable
        columnDefs={getColumnDefs()}
        columnTypes={{
          sortableFilterDefinitionColumn: {
            sortable: true,
            filter: true,
            filterParams: { newRowsAction: 'keep' },
            menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
          }
        }}
        height="450px"
        onGridReady={(params) => (gridApi.current = params.api)}
        onRowDataUpdated={(params) => {
          params.api.forEachNode((node) => {
            node.setSelected(node.data.selected);
          });
        }}
        onRowSelected={handleRowSelected}
        rowData={modelGroupsAvailable}
        rowSelection="multiple"
        suppressRowClickSelection
      />
    </Bricks.Div>
  );
};

const YearSettingsModelGroups = React.memo(React.forwardRef(YearSettingsModelGroupsComponent));

YearSettingsModelGroupsComponent.propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string.isRequired,
  modelGroupList: PropTypes.arrayOf(Object),
  onSave: PropTypes.func,
  yearSettingsModelGroupList: PropTypes.arrayOf(Object)
};

YearSettingsModelGroupsComponent.defaultProps = {
  className: null,
  modelGroupList: null,
  onSave: null,
  yearSettingsModelGroupList: null
};

export default YearSettingsModelGroups;
