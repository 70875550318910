import React from 'react';
import PropTypes from 'prop-types';
import { PriceGuaranteeItemsResponseDTOITM } from '@ovex/price-guarantee-web-api';

import {
  AGDatePicker,
  CustomHeaderLabel,
  GROUP_DISPLAY_TYPE,
  OvexAGTable
} from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';
import { useStateCallback } from '../../../../common/hooks';

import ModelGroupRowInnerRenderer from './ModelGroupRowInnerRenderer/ModelGroupRowInnerRenderer';
import { PGI_READ_ONLY_COLUMN_IDS } from './PriceGuaranteeItemTable.columnIds';
import { columnTypes, getColumnDefinitions } from './PriceGuaranteeItemTable.columnDefs';
import { handleGetContextMenuItems } from './PriceGuaranteeItemTable.contextMenu';
import { handleProcessCellFromClipboard } from './PriceGuaranteeItemTable.helpers';
import EditableModelGroupContext from './EditableModelGroupContext';
import PriceGuaranteeItemRow from './rowModel/PriceGuaranteeItemRow';

import './PriceGuaranteeItemTable.scss';

const propTypes = {
  onDisableEditable: PropTypes.func.isRequired,
  onEnableEditable: PropTypes.func.isRequired,
  onUpdateBulkPriceGuaranteeItems: PropTypes.func,
  priceGuaranteeItemsResponse: PropTypes.instanceOf(PriceGuaranteeItemsResponseDTOITM)
};

const defaultProps = {
  onUpdateBulkPriceGuaranteeItems: undefined,
  priceGuaranteeItemsResponse: null
};

const PriceGuaranteeItemTable = (props) => {
  const lsi = React.useContext(LsiContext);
  const table = React.useRef(null);

  const filterModelModelGroup = React.useRef(null);
  const [ editableModelGroup, setEditableModelGroup ] = useStateCallback(null);

  const { onEnableEditable, onDisableEditable } = props;
  const handleSetEditableModelGroup = (value, callback) => {
    const filterInstance = table.current.api.getFilterInstance(PGI_READ_ONLY_COLUMN_IDS.MODEL_GROUP);
    filterModelModelGroup.current = filterInstance.getModel();

    filterInstance.setModel({ filterType: 'set', values: [value] });
    table.current.api.onFilterChanged();

    setEditableModelGroup(value, callback);
    onEnableEditable();
  };
  const handleCancelEditModelGroup = React.useCallback(
    (callback) => {
      if (table.current) {
        const filterInstance = table.current.api.getFilterInstance(PGI_READ_ONLY_COLUMN_IDS.MODEL_GROUP);

        filterInstance.setModel(filterModelModelGroup.current);
        table.current.api.onFilterChanged();
      }

      setEditableModelGroup(null, callback);
      onDisableEditable();
    },
    [setEditableModelGroup, onDisableEditable]
  );

  const { priceGuarantee, emptyModelGroupList, pgItemList } = props.priceGuaranteeItemsResponse || {};
  const [priceGuaranteeItemRowList, setPriceGuaranteeItemRowList] = React.useState(undefined);
  React.useEffect(
    () => {
      const rowList = pgItemList?.map(PriceGuaranteeItemRow.constructFromDTO);

      emptyModelGroupList && emptyModelGroupList.forEach(mg => rowList?.push(PriceGuaranteeItemRow.createVirtualRow(mg)));

      setPriceGuaranteeItemRowList(rowList);
      handleCancelEditModelGroup(null);
    },
    [pgItemList, emptyModelGroupList, handleCancelEditModelGroup]
  );

  const modelGroupMap = React.useMemo(
    () => getModelGroupMap(priceGuarantee?.modelGroupList),
    [priceGuarantee]
  );
  const modelGroupMapRef = React.useRef();
  modelGroupMapRef.current = modelGroupMap;

  const handleDefaultGroupOrderComparator = (nodeA, nodeB) => {
    const orderA = modelGroupMapRef.current[nodeA.key]?.sortOrder;
    const orderB = modelGroupMapRef.current[nodeB.key]?.sortOrder;
    if (orderA < orderB) {
      return -1;
    } else if (orderA > orderB) {
      return 1;
    }
    return 0;
  };

  return (
    <EditableModelGroupContext.Provider value={editableModelGroup}>
      <OvexAGTable
        agContext={{
          editableModelGroup: editableModelGroup
        }}
        className='ovex-pg-PriceGuaranteeItemTable'
        columnDefs={getColumnDefinitions(lsi, priceGuarantee?.currency)}
        columnTypes={columnTypes}
        components={{
          datePicker: AGDatePicker,
          customHeaderLabel: CustomHeaderLabel,
          modelGroupRowInnerRenderer: ModelGroupRowInnerRenderer
        }}
        defaultGroupOrderComparator={handleDefaultGroupOrderComparator}
        enableRangeSelection
        getContextMenuItems={handleGetContextMenuItems}
        groupDisplayType={GROUP_DISPLAY_TYPE.groupRows}
        groupRowRendererParams={{
          innerRenderer: 'modelGroupRowInnerRenderer',
          suppressCount: true,
          suppressDoubleClickExpand: true,
          modelGroupMap: modelGroupMap,
          onSetEditableModelGroup: handleSetEditableModelGroup,
          onSaveChanges: props.onUpdateBulkPriceGuaranteeItems,
          onCancelEditModelGroup: handleCancelEditModelGroup,
          priceGuaranteeId: priceGuarantee?.id
        }}
        height='560px'
        processCellFromClipboard={handleProcessCellFromClipboard}
        ref={table}
        rowClassRules={rowClassRules}
        rowData={priceGuaranteeItemRowList}
      />
    </EditableModelGroupContext.Provider>
  );
};

const getModelGroupMap = (modelGroupList) => {
  const mgEntries = modelGroupList?.map(mg => [mg.code, mg]);
  return mgEntries ? Object.fromEntries(mgEntries) : {};
};

const rowClassRules = {
  'ovex-pg-PriceGuaranteeItemTable-row--new': (rowClassParams) => rowClassParams.node.group ? false : rowClassParams.data.isRowStatusNew(),
  'ovex-pg-PriceGuaranteeItemTable-row--delete': (rowClassParams) => rowClassParams.node.group ? false : rowClassParams.data.isDeleteFlag()
};

PriceGuaranteeItemTable.propTypes = propTypes;
PriceGuaranteeItemTable.defaultProps = defaultProps;

export default PriceGuaranteeItemTable;
