import { fixedRowLsiGetter, requiredValueGetter } from './UserDetailInfoTable.helper';
import UserInfoIconRenderer from './Icons/UserInfoIconRenderer';

export const COLUMN_IDS = {
  COLUMN_TYPE: 'rowId',
  USER_INFO_OWN: 'userInfoOwn',
  USER_INFO_EXPECTED: 'userInfoExpected',
  USER_INFO_IMITATED: 'userInfoImitated'
};

export const DYNAMIC_COLUMN_ID = {
  PREPOSITION: 'OVEX3'
};

export const getColumnDefinitions = (lsi) => {

  return [
    {
      colId: COLUMN_IDS.COLUMN_TYPE,
      cellClass: ['ovex-pg-ValidationResultHeaderTable-column--header'],
      valueGetter: 'data && data.rowId',
      valueFormatter: ({ context, value, data }) => fixedRowLsiGetter({ context, value, data }),
      minWidth: 300,
      maxWidth: 350
    },
    {
      colId: COLUMN_IDS.USER_INFO_OWN,
      headerName: lsi.getLSIItem('SUPPORT.USER_IMITATION.USER_OWN'),
      cellRenderer: (props) => UserInfoIconRenderer({ ...props, propertyName: COLUMN_IDS.USER_INFO_OWN }),
      valueGetter: ({ data, context }) => requiredValueGetter({ data, context, propertyName: COLUMN_IDS.USER_INFO_OWN }),
      flex: 1
    },
    {
      colId: COLUMN_IDS.USER_INFO_EXPECTED,
      headerName: lsi.getLSIItem('SUPPORT.USER_IMITATION.USER_EXPECTED'),
      cellRenderer: (props) => UserInfoIconRenderer({ ...props, propertyName: COLUMN_IDS.USER_INFO_EXPECTED }),
      valueGetter: ({ data, context }) => requiredValueGetter({ data, context, propertyName: COLUMN_IDS.USER_INFO_EXPECTED }),
      flex: 1
    }
    ,
    {
      colId: COLUMN_IDS.USER_INFO_IMITATED,
      headerName: lsi.getLSIItem('SUPPORT.USER_IMITATION.USER_IMITATED'),
      cellRenderer: (props) => UserInfoIconRenderer({ ...props, propertyName: COLUMN_IDS.USER_INFO_IMITATED }),
      valueGetter: ({ data, context }) => requiredValueGetter({ data, context, propertyName: COLUMN_IDS.USER_INFO_IMITATED }),
      flex: 1
    }
  ];
};