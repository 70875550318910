import { handleDonePercentNumberAggFunc, handleDonePercentNumberValueGetter } from '../../../utils/statisticsHelpers';
import { contractNameFormatter } from '../../../utils/formatters';
import { dateTimeSecondsFormatter, simpleNullishValueFormatter } from '../../../../common/utils/formatters';
import { handleNumberValueFormatter } from '../utils/helpers';

import { COLUMN_GROUP_IDS, COLUMN_IDS } from './DealerContractsTable.columnIds';

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn',
  ROC_TYPE_CD: 'rocTypeColumn',
  RCP_TYPE_CD: 'rcpTypeColumn',
  NUMERIC_AGG_CD: 'numericColumn' // "numericColumn" = AG-Grid column type
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  },
  [COLUMN_TYPE_ENUM.ROC_TYPE_CD]: {
    cellClass: 'ovex-dealer-contracts-table-column--roc',
    valueFormatter: handleNumberValueFormatter,
    aggFunc: 'sum'
  },
  [COLUMN_TYPE_ENUM.RCP_TYPE_CD]: {
    cellClass: 'ovex-dealer-contracts-table-column--rcp',
    valueFormatter: handleNumberValueFormatter,
    aggFunc: 'sum'
  }
};

export const getAutoGroupColumnDef = (lsi) => {
  return {
    cellRenderer: 'agGroupCellRenderer',
    cellClassRules: {
      'empty-cell': (cellClassParams) => {
        return cellClassParams.value === undefined;
      }
    },
    cellRendererParams: {
      checkbox: true
    },
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.YEAR_AREA')
  };
};

export const getColumnDefinitions = (lsi, hiddenAT, hiddenATS, doneCountGroupExpanded) => {
  return [
    {
      colId: COLUMN_IDS.YEAR,
      valueGetter: 'data && data.contract.negotiation.year',
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.YEAR'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      rowGroup: true,
      hide: true
    },
    {
      colId: COLUMN_IDS.AREA_NAME,
      valueGetter: 'data && data.headquartersDealer.areaName',
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.AREA'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      rowGroup: true,
      hide: true
    },
    {
      colId: COLUMN_IDS.DEALER_NUMBER,
      valueGetter: 'data && data.headquartersDealer.dealerNumber',
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DEALER'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 140,
      cellRenderer: 'agGroupCellRenderer',
      cellClass: 'ovex-dealer-contracts-table-column--dealer-number',
      cellRendererParams: { checkbox: true }
    },
    { colId: COLUMN_IDS.DEALER_NAME,
      valueGetter: 'data && data.headquartersDealer.dealerName',
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.NAME'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD] },
    {
      colId: COLUMN_IDS.CONTRACT_NAME,
      valueGetter: 'data && data.contract.negotiation.name',
      valueFormatter: handleContractNameValueFormatter,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.CONTRACT_ADDITION'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 180
    },
    {
      colId: COLUMN_IDS.CONTRACT_STATUS,
      valueGetter: 'data && data.contract.status',
      valueFormatter: handleContractStatusValueFormatter,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.STATUS'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      width: 120
    },
    {
      groupId: COLUMN_GROUP_IDS.DEALER_NUMBER_GROUP,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TENTATIVE'),
      headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerContracts-column-working'],
      marryChildren: true,
      children: [
        {
          colId: COLUMN_IDS.DEALER_NUMBER_AT,
          valueGetter: 'data && data.getSumATDealerNumber()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.ROC'),
          headerClass: ['ovex-DealerContracts-column-working'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenAT
        },
        {
          colId: COLUMN_IDS.DEALER_NUMBER_ATS,
          valueGetter: 'data && data.getSumATSDealerNumber()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RCP'),
          headerClass: ['ovex-DealerContracts-column-working'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.RCP_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenATS
        }
      ]
    },
    {
      groupId: COLUMN_GROUP_IDS.RECOMMENDED_NUMBER_GROUP,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RECOMMENDED'),
      headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerContracts-column-working'],
      marryChildren: true,
      children: [
        {
          colId: COLUMN_IDS.RECOMMENDED_NUMBER_AT,
          valueGetter: 'data && data.getSumATRecommendedNumber()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.ROC'),
          headerClass: ['ovex-DealerContracts-column-working'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenAT
        },
        {
          colId: COLUMN_IDS.RECOMMENDED_NUMBER_ATS,
          valueGetter: 'data && data.getSumATSRecommendedNumber()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RCP'),
          headerClass: ['ovex-DealerContracts-column-working'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.RCP_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenATS
        }
      ]
    },
    {
      groupId: COLUMN_GROUP_IDS.APPROVAL_NUMBER_GROUP,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BINDING'),
      headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerContracts-column-working'],
      marryChildren: true,
      children: [
        {
          colId: COLUMN_IDS.APPROVAL_NUMBER_AT,
          valueGetter: 'data && data.getSumATApprovalNumber()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.ROC'),
          headerClass: ['ovex-DealerContracts-column-working'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenAT
        },
        {
          colId: COLUMN_IDS.APPROVAL_NUMBER_ATS,
          valueGetter: 'data && data.getSumATSApprovalNumber()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RCP'),
          headerClass: ['ovex-DealerContracts-column-working'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.RCP_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenATS
        }
      ]
    },
    {
      groupId: COLUMN_GROUP_IDS.APPROVED_NUMBER_GROUP,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.APPROVED'),
      headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerContracts-column-approved'],
      marryChildren: true,
      children: [
        {
          colId: COLUMN_IDS.APPROVED_NUMBER_AT,
          valueGetter: 'data && data.getSumATApprovedNumber()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.ROC'),
          headerClass: ['ovex-DealerContracts-column-approved'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenAT
        },
        {
          colId: COLUMN_IDS.APPROVED_NUMBER_ATS,
          valueGetter: 'data && data.getSumATSApprovedNumber()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RCP'),
          headerClass: ['ovex-DealerContracts-column-approved'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.RCP_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenATS
        }
      ]
    },

    {
      groupId: COLUMN_GROUP_IDS.STATISTICS_DONE_COUNT_GROUP,
      headerName: doneCountGroupExpanded
        ? lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DETAIL_AT_PERIOD_DONE')
        : lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE'),
      headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerContracts-column-statistics'],
      marryChildren: true,
      openByDefault: doneCountGroupExpanded,
      children: [
        {
          colId: COLUMN_IDS.STATISTICS_DONE_COUNT_AT_INVOICED,
          valueGetter: 'data && data.getSumATStatisticsInvoicedCount()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.INVOICED'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COMMISSION_STATUS_INVOICED'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: 120,
          hide: hiddenAT,
          columnGroupShow: 'open'
        },
        {
          colId: COLUMN_IDS.STATISTICS_DONE_COUNT_AT_PRODUCTION,
          valueGetter: 'data && data.getSumATStatisticsProductionCount()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COMMISSION_STATUS_PRODUCTION'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: 120,
          hide: hiddenAT,
          columnGroupShow: 'open'
        },
        {
          colId: COLUMN_IDS.STATISTICS_DONE_COUNT_AT_PLANNED,
          valueGetter: 'data && data.getSumATStatisticsPlannedCount()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COMMISSION_STATUS_PLANNED'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: 120,
          hide: hiddenAT,
          columnGroupShow: 'open'
        },
        {
          colId: COLUMN_IDS.STATISTICS_DONE_COUNT_AT_TOTAL,
          valueGetter: 'data && data.getSumATStatisticsDoneCount()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.ROC'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenAT
        },
        {
          colId: COLUMN_IDS.STATISTICS_DONE_COUNT_ATS,
          valueGetter: 'data && data.getSumATSStatisticsDoneNumber()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RCP'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.RCP_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenATS
        }
      ]
    },
    {
      groupId: COLUMN_GROUP_IDS.STATISTICS_DONE_PERCENT_GROUP,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE_PERCENT'),
      headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerContracts-column-statistics'],
      marryChildren: true,
      children: [
        {
          colId: COLUMN_IDS.STATISTICS_DONE_PERCENT_AT,
          valueGetter: handleDonePercentROCValueGetter,
          valueFormatter: null,
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.ROC'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenAT,
          aggFunc: handleDonePercentNumberAggFunc
        },
        {
          colId: COLUMN_IDS.STATISTICS_DONE_PERCENT_ATS,
          valueGetter: handleDonePercentRCPValueGetter,
          valueFormatter: null,
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RCP'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.RCP_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenATS,
          aggFunc: handleDonePercentNumberAggFunc
        }
      ]
    },
    {
      groupId: COLUMN_GROUP_IDS.STATISTICS_REMAINS_COUNT_GROUP,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.REMAINS'),
      headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerContracts-column-statistics'],
      marryChildren: true,
      children: [
        {
          colId: COLUMN_IDS.STATISTICS_REMAINS_COUNT_AT,
          valueGetter: 'data && data.getSumATStatisticsRemainsCount()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.ROC'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenAT
        },
        {
          colId: COLUMN_IDS.STATISTICS_REMAINS_COUNT_ATS,
          valueGetter: 'data && data.getSumATSStatisticsRemainsNumber()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RCP'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.RCP_TYPE_CD],
          width: calculateWorkingColumnWidth(hiddenAT, hiddenATS),
          hide: hiddenATS
        }
      ]
    },
    {
      groupId: COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_COUNT_GROUP,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.POTENTIAL'),
      headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerContracts-column-statistics'],
      marryChildren: true,
      children: [
        {
          colId: COLUMN_IDS.STATISTICS_POTENTIAL_COUNT_AT_PRODUCTION,
          valueGetter: 'data && data.getSumATStatisticsPotentialProductionCount()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: 120,
          hide: hiddenAT,
          columnGroupShow: 'open'
        },
        {
          colId: COLUMN_IDS.STATISTICS_POTENTIAL_COUNT_AT_PLANNED,
          valueGetter: 'data && data.getSumATStatisticsPotentialPlannedCount()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: 120,
          hide: hiddenAT,
          columnGroupShow: 'open'
        },
        {
          colId: COLUMN_IDS.STATISTICS_POTENTIAL_COUNT_AT_TOTAL,
          valueGetter: 'data && data.getSumATStatisticsPotentialCount()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TOTAL'),
          headerClass: ['ovex-DealerContracts-column-statistics'],
          type: [COLUMN_TYPE_ENUM.NUMERIC_AGG_CD, COLUMN_TYPE_ENUM.ROC_TYPE_CD],
          width: 170,
          hide: hiddenAT
        }
      ]
    },
    {
      colId: COLUMN_IDS.CONTRACT_STATISTICS_TIMESTAMP,
      valueGetter: 'data && data.contract.statisticsTimestamp',
      valueFormatter: handleContractStatisticsTimestampValueFormatter,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.STATS_VALID_TO'),
      width: 160
    }
  ];
};

const calculateWorkingColumnWidth = (hiddenAT, hiddenATS) => {
  if (hiddenAT === hiddenATS) {
    return 85;
  }
  return 170;
};

const handleDonePercentROCValueGetter = (valueGetterParams) => {
  return handleDonePercentNumberValueGetter(valueGetterParams, valueGetterParams.data.getSumATApprovedNumber(), valueGetterParams.data.getSumATStatisticsDoneCount());
};

const handleDonePercentRCPValueGetter = (valueGetterParams) => {
  return handleDonePercentNumberValueGetter(valueGetterParams, valueGetterParams.data.getSumATSApprovedNumber(), valueGetterParams.data.getSumATSStatisticsDoneNumber());
};

const handleContractNameValueFormatter = (valueFormatterParams) => {
  if (valueFormatterParams.node && !valueFormatterParams.node.group) {
    return contractNameFormatter(valueFormatterParams.data.contract, valueFormatterParams.context.agLsi);
  }
  return '';
};

const handleContractStatusValueFormatter = (valueFormatterParams) => {
  return valueFormatterParams.context.agLsi.getLSIItem('ANNUAL_TARGET.ENUM_LABELS.CONTRACT_STATUS')[valueFormatterParams.value];
};

const handleContractStatisticsTimestampValueFormatter = (valueFormatterParams) => {
  if (valueFormatterParams.node.group) {
    return '';
  }
  const value = valueFormatterParams.value;
  const formatters = valueFormatterParams.context.agLsi.getFormatters();
  return simpleNullishValueFormatter(dateTimeSecondsFormatter(value, formatters), '-');
};
