import SwapCommissionPvtIconRenderer from '../SwapCommissionIcons/SwapCommissionPvtIconRenderer';

import { pvtPositionValueGetter } from './SwapCommissionPvtTable.helpers';

export const COLUMN_IDS = {
  COMMISSION: 'commission',
  COMMISSION_YEAR: 'commissionYear',
  PVT: 'pvt',
  PVT_POSITION: 'pvtPosition',
  DEALER_NUMBER: 'dealerNumber',
  MODEL: 'model',
  COLOUR: 'colour',
  INTERIOR: 'interior',
  PACKETS: 'packets',
  WEEK_WISH: 'weekWish'
};

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  }
};

export const getColumnDefinitions = (lsi) => {

  return [
    {
      colId: COLUMN_IDS.COMMISSION_YEAR,
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.COMMISSION_YEAR'),
      width: 130,
      valueGetter: 'data && data.commissionYear',
      initialHide: true,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD]
    },
    {
      colId: COLUMN_IDS.COMMISSION,
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.COMMISSION_NUMBER'),
      width: 110,
      valueGetter: 'data && data.commission',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD]
    },
    {
      colId: COLUMN_IDS.MODEL,
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.MODEL'),
      width: 110,
      valueGetter: 'data && data.model',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD]
    },
    {
      colId: COLUMN_IDS.DEALER_NUMBER,
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.DEALER_NUMBER'),
      width: 160,
      valueGetter: 'data && data.dealerNumber',
      initialHide: true,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD]
    },
    {
      colId: COLUMN_IDS.COLOUR,
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.COLOUR'),
      width: 110,
      valueGetter: 'data && data.colour',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD]
    },
    {
      colId: COLUMN_IDS.INTERIOR,
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.INTERIOR'),
      width: 110,
      valueGetter: 'data && data.interior',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD]
    },
    {
      colId: COLUMN_IDS.WEEK_WISH,
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.WEEK_WISH'),
      width: 150,
      valueGetter: 'data && data.weekWish',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD]
    },
    {
      colId: COLUMN_IDS.PVT,
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.PVT'),
      width: 110,
      valueGetter: 'data && data.pvt',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD]
    },
    {
      colId: COLUMN_IDS.PVT_POSITION,
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.PVT_SHIFT'),
      width: 120,
      cellRenderer: SwapCommissionPvtIconRenderer,
      valueGetter: pvtPositionValueGetter
    },
    {
      colId: COLUMN_IDS.PACKETS,
      headerName: lsi.getLSIItem('PVT.TABLE_HEADER.PACKETS'),
      flex: 1,
      minWidth: 150,
      valueGetter: 'data && data.equipment && data.equipment.pkgList',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD]
    }
  ];
};