import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import { ContractDetailDTO } from '@ovex/annual-target-web-api';

import { OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';
import { AT_SALES_EXPORT_COLUMN_KEYS, COLUMN_IDS } from '../utils/tableBaseColumnDefs';

import DealerPeriodAnnualTargetSalesRow from './DealerPeriodAnnualTargetSalesRow';
import {
  columnTypes,
  getAutoGroupColumnDef,
  getColumnDefinitions
} from './DealerPeriodAnnualTargetSalesTable.columnDefs';
import { handleGetContextMenuItems } from './DealerPeriodAnnualTargetSalesTable.contextMenu';

import './DealerPeriodAnnualTargetSalesTable.scss';

const propTypes = {
  className: PropTypes.string,
  contract: PropTypes.instanceOf(ContractDetailDTO),
  editableApprovalNumberColumn: PropTypes.bool,
  editableDealerNumberColumn: PropTypes.bool,
  gridOptions: PropTypes.object,
  onShowCommissionListWithParams: PropTypes.func,
  periodAnnualTargetSalesList: PropTypes.arrayOf(PropTypes.instanceOf(DealerPeriodAnnualTargetSalesRow))
};

const defaultProps = {
  className: null,
  contract: null,
  editableApprovalNumberColumn: false,
  editableDealerNumberColumn: false,
  gridOptions: null,
  onShowCommissionListWithParams: undefined,
  periodAnnualTargetSalesList: []
};

const DealerPeriodAnnualTargetSalesTable = React.memo(React.forwardRef((props, ref) => {
  const lsi = React.useContext(LsiContext);
  const tableRef = React.useRef(null);

  React.useImperativeHandle(
    ref,
    () => ({
      discardChanges: () => {
        if (!tableRef.current) {
          return;
        }
        const modifiedData = [];

        tableRef.current.api.forEachLeafNode(rowNode => rowNode.data.isModified() && modifiedData.push(rowNode.data));

        if (modifiedData.length > 0) {
          modifiedData.forEach(data => data.discardChanges());
          tableRef.current.api.applyTransaction({
            update: modifiedData
          });
        }
      }
    })
  );

  React.useEffect(
    () => {
      tableRef.current && tableRef.current.api.refreshCells({ columns: [ COLUMN_IDS.AT_ATS_APPROVAL_NUMBER ], force: true });
    },
    [props.editableApprovalNumberColumn]
  );

  React.useEffect(
    () => {
      tableRef.current && tableRef.current.api.refreshCells({ columns: [ COLUMN_IDS.AT_ATS_DEALER_NUMBER ], force: true });
    },
    [props.editableDealerNumberColumn]
  );

  const classNames = ['ovex-DealerPeriodAnnualTargetSalesTable'];
  props.className && classNames.push(props.className);

  const columnDefs = React.useMemo(
    () => getColumnDefinitions(lsi, props.editableDealerNumberColumn, props.editableApprovalNumberColumn),
    [lsi, props.editableDealerNumberColumn, props.editableApprovalNumberColumn]
  );
  const getRowId = React.useCallback(
    (params) => String(params.data.id),
    [props.modelGroupAnnualTargetList]
  );

  return (
    <Bricks.Div
      className={classNames.join(' ')}
    >
      <OvexAGTable
        agContext={{
          exportColumnKeys: AT_SALES_EXPORT_COLUMN_KEYS,
          onShowCommissionListWithParams: props.onShowCommissionListWithParams,
          statisticsRequestId: props.contract?.statisticsRequestId,
          statisticsType: props.contract?.statisticsType,
          negotiationType: props.contract?.negotiation.type
        }}
        alwaysShowVerticalScroll
        animateRows
        autoGroupColumnDef={getAutoGroupColumnDef(lsi)}
        columnDefs={columnDefs}
        columnTypes={columnTypes}
        domLayout='autoHeight'
        enableRangeSelection
        getContextMenuItems={handleGetContextMenuItems}
        getRowId={getRowId}
        gridOptions={props.gridOptions}
        groupHeaderHeight={0}
        height='240px'
        highlightLeafOddEvenRows
        ref={tableRef}
        rowData={props.periodAnnualTargetSalesList}
        suppressMovableColumns
      />
    </Bricks.Div>
  );
}));

DealerPeriodAnnualTargetSalesTable.propTypes = propTypes;
DealerPeriodAnnualTargetSalesTable.defaultProps = defaultProps;

export default DealerPeriodAnnualTargetSalesTable;
