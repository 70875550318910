import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';
import { useConfirmModal } from '../../../../common/hooks';
import NegotiationsButtonBar from '../NegotiationsButtonBar/NegotiationsButtonBar';

import NegotiationRow from './NegotiationRow';
import { getContextMenu } from './NegotiationsTableContextMenu';
import { columnTypes, getColumnDefinitions } from './NegotiationsTable.columnDefs';
import { handlePostSort } from './NegotiationsTable.utils';

import './NegotiationsTable.scss';

const propTypes = {
  negotiationList: PropTypes.arrayOf(PropTypes.instanceOf(NegotiationRow)),
  onCancelNegotiationClosure: PropTypes.func,
  onCreateNegotiationClosure: PropTypes.func,
  onReloadAnnualTargetYears: PropTypes.func.isRequired,
  onShiftNegotiationWorkflow: PropTypes.func,
  onShowNegotiationCreationModalForm: PropTypes.func,
  onShowNegotiationDetailModalForm: PropTypes.func,
  onShowNegotiationUpdateModalForm: PropTypes.func,
  onShowYearSettingsCreationModalForm: PropTypes.func,
  onShowYearSettingsDetailModalForm: PropTypes.func,
  onShowYearSettingsUpdateModalForm: PropTypes.func
};

const defaultProps = {
  negotiationList: null,
  onCancelNegotiationClosure: undefined,
  onCreateNegotiationClosure: undefined,
  onShiftNegotiationWorkflow: undefined,
  onShowNegotiationCreationModalForm: undefined,
  onShowNegotiationDetailModalForm: undefined,
  onShowNegotiationUpdateModalForm: undefined,
  onShowYearSettingsCreationModalForm: undefined,
  onShowYearSettingsDetailModalForm: undefined,
  onShowYearSettingsUpdateModalForm: undefined
};

const NegotiationsTable = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const columnDefs = React.useMemo(
    () => {
      return getColumnDefinitions(lsi);
    },
    [lsi]
  );

  const [ renderConfirmShiftModal, handleShowConfirmShift ] = useConfirmModal(props.onShiftNegotiationWorkflow, {
    content: (
      <React.Fragment>
        {lsi.getLSIItem('ANNUAL_TARGET.CONFIRM_MESSAGE.NEGOTIATION_ACTIVATION_INFO')}
        <br/>
        {lsi.getLSIItem('ANNUAL_TARGET.CONFIRM_MESSAGE.NEGOTIATION_ACTIVATION')}
      </React.Fragment>
    )
  });

  const [ renderConfirmCreateNegotiationClosure, handleShowConfirmCreateNegotiationClosure ] = useConfirmModal(props.onCreateNegotiationClosure, {
    content: lsi.getLSIItem('ANNUAL_TARGET.CONFIRM_MESSAGE.NEGOTIATION_CLOSURE_INFO')
  });

  const [ renderConfirmCancelNegotiationClosure, handleShowConfirmCancelNegotiationClosure ] = useConfirmModal(props.onCancelNegotiationClosure, {
    content: lsi.getLSIItem('ANNUAL_TARGET.CONFIRM_MESSAGE.NEGOTIATION_CLOSURE_CANCEL_INFO')
  });

  return (
    <Bricks.Div className='ovex-negotiations-table'>
      <NegotiationsButtonBar
        onReload={props.onReloadAnnualTargetYears}
        onShowYearSettingsCreationModalForm={props.onShowYearSettingsCreationModalForm}
      />
      <OvexAGTable
        agContext={{
          onShiftNegotiationWorkflow: handleShowConfirmShift,
          onCancelNegotiationClosure: handleShowConfirmCancelNegotiationClosure,
          onCreateNegotiationClosure: handleShowConfirmCreateNegotiationClosure,
          onShowNegotiationCreationModalForm: props.onShowNegotiationCreationModalForm,
          onShowNegotiationDetailModalForm: props.onShowNegotiationDetailModalForm,
          onShowNegotiationUpdateModalForm: props.onShowNegotiationUpdateModalForm,
          onShowYearSettingsDetailModalForm: props.onShowYearSettingsDetailModalForm,
          onShowYearSettingsUpdateModalForm: props.onShowYearSettingsUpdateModalForm
        }}
        autoGroupColumnDef={{
          pinned: 'left',
          width: 120
        }}
        columnDefs={columnDefs}
        columnTypes={columnTypes}
        getContextMenuItems={getContextMenu}
        groupDefaultExpanded={-1}
        groupHideOpenParents
        height='640px'
        postSortRows={handlePostSort}
        rowData={props.negotiationList}
      />
      {renderConfirmShiftModal()}
      {renderConfirmCreateNegotiationClosure()}
      {renderConfirmCancelNegotiationClosure()}
    </Bricks.Div>
  );
});

NegotiationsTable.propTypes = propTypes;
NegotiationsTable.defaultProps = defaultProps;

export default NegotiationsTable;
