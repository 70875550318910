import { EXCEL_STYLES_IDS } from '../../../../common/components/ag-grid';
import { integerFormatter, percent2Formatter } from '../../../../common/utils/formatters';
import { contractNameFormatter } from '../../../utils/formatters';
import {
  calculateDoneNumber,
  calculateDonePercentNumber,
  calculatePotentialNumber,
  calculateRemainsNumber
} from '../../../utils/statisticsHelpers';

export const CONTRACT_COLUMN_IDS = {
  AREA_NAME: 'areaName',
  CONTRACT_NAME: 'contractName',
  CONTRACT_STATUS: 'contractStatus',
  CONTRACT_MODEL: 'contractModel',
  DEALER_NAME: 'dealerName',
  DEALER_NUMBER: 'dealerNumber',
  YEAR: 'year'
};

const TOTAL_COLUMN_IDS = {
  GROUP_ID: 'totalColumnGroup',
  DEALER_NUMBER: 'totalDealerNumber',
  RECOMMENDED_NUMBER: 'totalRecommendedNumber',
  APPROVAL_NUMBER: 'totalApprovalNumber',
  APPROVED_NUMBER: 'totalApprovedNumber',

  STATISTICS_INVOICED_COUNT: 'statistics_invoicedCount',
  STATISTICS_PRODUCTION_COUNT: 'statistics_productionCount',
  STATISTICS_PLANNED_COUNT: 'statistics_plannedCount',
  STATISTICS_DONE_NUMBER: 'statistics_doneNumber',
  STATISTICS_DONE_PERCENTAGE: 'statistics_DonePercentage',
  STATISTICS_REMAINING_NUMBER: 'statistics_remainingNumber',
  STATISTICS_POTENTIAL_PRODUCTION_COUNT: 'statistics_potentialProductionCount',
  STATISTICS_POTENTIAL_PLANNED_COUNT: 'statistics_potentialPlannedCount',
  STATISTICS_POTENTIAL_COUNT: 'statistics_potentialCount'
};

const getColumnIdsForDynamicColumn = (code) => {
  return {
    GROUP_ID: `${code}_columnGroup`,
    DEALER_NUMBER: `${code}_dealerNumber`,
    RECOMMENDED_NUMBER: `${code}_recommendedNumber`,
    APPROVAL_NUMBER: `${code}_approvalNumber`,
    APPROVED_NUMBER: `${code}_approvedNumber`,

    STATISTICS_INVOICED_COUNT: `${code}_statistics_invoicedCount`,
    STATISTICS_PRODUCTION_COUNT: `${code}_statistics_productionCount`,
    STATISTICS_PLANNED_COUNT: `${code}_statistics_plannedCount`,
    STATISTICS_DONE_NUMBER: `${code}_statistics_doneNumber`,
    STATISTICS_DONE_PERCENTAGE: `${code}_statistics_DonePercentage`,
    STATISTICS_REMAINING_NUMBER: `${code}_statistics_remainingNumber`,
    STATISTICS_POTENTIAL_PRODUCTION_COUNT: `${code}_statistics_potentialProductionCount`,
    STATISTICS_POTENTIAL_PLANNED_COUNT: `${code}_statistics_potentialPlannedCount`,
    STATISTICS_POTENTIAL_COUNT: `${code}_statistics_potentialCount`
  };
};

const fixDataValue = (value) => value != null ? value : 0;
const modelGroupFormatterContract = (params) => {
  if (params.value === null || params.node.group) {
    return params.value;
  } else {
    return params.value + ' - ' + params.node.data?.modelGroupAnnualTarget.modelGroupName;
  }
};

const handleNumberValueFormatter = (valueFormatterParams) => {
  const value = valueFormatterParams.value;
  const formatters = valueFormatterParams.context.agLsi.getFormatters();

  return integerFormatter(value, formatters);
};

const handlePercentValueFormatter = (valueFormatterParams) => {
  const value = valueFormatterParams.value;
  const formatters = valueFormatterParams.context.agLsi.getFormatters();

  return percent2Formatter(value, formatters);
};

export const columnTypes = {
  sortableFilterDefinitionColumn: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  },
  atDataColumn: {
    valueFormatter: handleNumberValueFormatter,
    cellClass: ['ovex-DealerContractsATModelGroupViewTable-column-cell', EXCEL_STYLES_IDS.NUMBER_0_DECIMALS],
    aggFunc: 'sum',
    width: 110
  },
  atDataColumnPercent: {
    valueFormatter: handlePercentValueFormatter,
    cellClass: ['ovex-DealerContractsATModelGroupViewTable-column-cell', EXCEL_STYLES_IDS.PERCENT_2_DECIMALS],
    width: 110
  }
};

export const autoGroupColumnDef = {
  valueGetter: (valueGetterParams) => {
    if (valueGetterParams.colDef.showRowGroup === CONTRACT_COLUMN_IDS.YEAR) {
      return handleYearValueGetter(valueGetterParams);
    } else if (valueGetterParams.colDef.showRowGroup === CONTRACT_COLUMN_IDS.AREA_NAME) {
      return handleAreaNameValueGetter(valueGetterParams);
    }
    return null;
  },
  type: ['sortableFilterDefinitionColumn'],
  width: 150,
  pinned: 'left',
  cellRendererParams: {
    suppressCount: true
  }
};

const handleYearValueGetter = (valueGetterParams) => valueGetterParams.data?.contract.negotiation.year;
const handleAreaNameValueGetter = (valueGetterParams) => valueGetterParams.data?.contract.headquartersDealer.areaName;

export const getColumnDefinitions = (lsi, dynamicColumnDataList) => {

  return [
    {
      colId: CONTRACT_COLUMN_IDS.YEAR,
      valueGetter: (valueGetterParams) => {
        let data = valueGetterParams.data;
        if (valueGetterParams.node.group && valueGetterParams.node.leafGroup) {
          const headquartersBranchRowNode = valueGetterParams.node.allLeafChildren?.[0];
          data = headquartersBranchRowNode?.data;
        }
        return data?.contract.negotiation.year;
      },
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.YEAR'),
      type: ['sortableFilterDefinitionColumn'],
      width: 80,
      rowGroup: true,
      hide: true
    },
    {
      colId: CONTRACT_COLUMN_IDS.AREA_NAME,
      valueGetter: (valueGetterParams) => {
        let data = valueGetterParams.data;
        if (valueGetterParams.node.group && valueGetterParams.node.leafGroup) {
          const headquartersBranchRowNode = valueGetterParams.node.allLeafChildren?.[0];
          data = headquartersBranchRowNode?.data;
        }
        return data?.contract.headquartersDealer.areaName;
      },
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.AREA'),
      type: ['sortableFilterDefinitionColumn'],
      width: 160,
      rowGroup: true,
      hide: true
    },
    {
      colId: CONTRACT_COLUMN_IDS.DEALER_NUMBER,
      valueGetter: (valueGetterParams) => {
        let data = valueGetterParams.data;
        if (valueGetterParams.node.group && valueGetterParams.node.leafGroup) {
          const headquartersBranchRowNode = valueGetterParams.node.allLeafChildren?.[0];
          data = headquartersBranchRowNode?.data;
        }
        return data?.contract.headquartersDealer.dealerNumber;
      },
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DEALER'),
      cellClass: [EXCEL_STYLES_IDS.STRING],
      type: ['sortableFilterDefinitionColumn'],
      width: 120,
      pinned: 'left',
      rowGroup: true,
      hide: true
    },
    {
      colId: CONTRACT_COLUMN_IDS.DEALER_NAME,
      valueGetter: (valueGetterParams) => {
        let data = valueGetterParams.data;
        if (valueGetterParams.node.group && valueGetterParams.node.leafGroup) {
          const headquartersBranchRowNode = valueGetterParams.node.allLeafChildren?.[0];
          data = headquartersBranchRowNode?.data;
        }
        return data?.contract.headquartersDealer.dealerName;
      },
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.NAME'),
      type: ['sortableFilterDefinitionColumn'],
      width: 200,
      pinned: 'left'
    },
    {
      colId: CONTRACT_COLUMN_IDS.CONTRACT_NAME,
      valueGetter: (valueGetterParams) => {
        let data = valueGetterParams.data;
        if (valueGetterParams.node.group && valueGetterParams.node.leafGroup) {
          const headquartersBranchRowNode = valueGetterParams.node.allLeafChildren?.[0];
          data = headquartersBranchRowNode?.data;
        }
        return data?.contract.negotiation.name;
      },
      valueFormatter: (valueFormatterParams) => {
        if (valueFormatterParams.node.group && valueFormatterParams.node.leafGroup) {
          const headquartersBranchRowNode = valueFormatterParams.node.allLeafChildren?.[0];
          if (headquartersBranchRowNode?.data) {
            return contractNameFormatter(headquartersBranchRowNode.data.contract, valueFormatterParams.context.agLsi);
          }
        }
        if (!valueFormatterParams.node.group) {
          // handler is called from as a standard *valueFormatter* (rendering flow) and also from excel export *processCellCallback*
          const data = valueFormatterParams.data || valueFormatterParams.node.data;
          return contractNameFormatter(data.contract, valueFormatterParams.context.agLsi);
        }
        return '';
      },
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.CONTRACT_ADDITION'),
      type: ['sortableFilterDefinitionColumn'],
      width: 180
    },
    {
      colId: CONTRACT_COLUMN_IDS.CONTRACT_STATUS,
      valueGetter: (valueGetterParams) => {
        let data = valueGetterParams.data;
        if (valueGetterParams.node.group && valueGetterParams.node.leafGroup) {
          const headquartersBranchRowNode = valueGetterParams.node.allLeafChildren?.[0];
          data = headquartersBranchRowNode?.data;
        }
        return data?.contract.status;
      },
      valueFormatter: (valueFormatterParams) => valueFormatterParams.context.agLsi.getLSIItem('ANNUAL_TARGET.ENUM_LABELS.CONTRACT_STATUS')[valueFormatterParams.value],
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.STATUS'),
      type: ['sortableFilterDefinitionColumn'],
      width: 120
    },
    {
      colId: CONTRACT_COLUMN_IDS.CONTRACT_MODEL,
      valueGetter: (valueGetterParams) => valueGetterParams.data?.modelGroupAnnualTarget.modelGroup,
      valueFormatter: modelGroupFormatterContract,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL'),
      type: ['sortableFilterDefinitionColumn'],
      width: 150
    },
    {
      groupId: TOTAL_COLUMN_IDS.GROUP_ID,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TOTAL_AT_MODEL_GROUPS'),
      headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerContractsATModelGroupViewTable-column-header-total'],
      marryChildren: true,
      openByDefault: true,
      children: [
        {
          colId: TOTAL_COLUMN_IDS.DEALER_NUMBER,
          valueGetter: getValueGetterTotalValue('atDealerNumber'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TENTATIVE'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-working'],
          type: ['atDataColumn']
        },
        {
          colId: TOTAL_COLUMN_IDS.RECOMMENDED_NUMBER,
          valueGetter: getValueGetterTotalValue('atRecommendedNumber'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RECOMMENDED'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-working'],
          type: ['atDataColumn']
        },
        {
          colId: TOTAL_COLUMN_IDS.APPROVAL_NUMBER,
          valueGetter: getValueGetterTotalValue('atApprovalNumber'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BINDING'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-working'],
          type: ['atDataColumn']
        },
        {
          colId: TOTAL_COLUMN_IDS.APPROVED_NUMBER,
          valueGetter: getValueGetterTotalValue('atApprovedNumber'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.APPROVED'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-approved'],
          type: ['atDataColumn']
        },
        {
          colId: TOTAL_COLUMN_IDS.STATISTICS_INVOICED_COUNT,
          valueGetter: getValueGetterTotalValue('atStatisticsInvoicedCount'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.INVOICED_DONE'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.INVOICED_DONE'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
          type: ['atDataColumn'],
          columnGroupShow: 'open'
        },
        {
          colId: TOTAL_COLUMN_IDS.STATISTICS_PRODUCTION_COUNT,
          valueGetter: getValueGetterTotalValue('atStatisticsProductionCount'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION_DONE'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION_DONE'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
          type: ['atDataColumn'],
          columnGroupShow: 'open'
        },
        {
          colId: TOTAL_COLUMN_IDS.STATISTICS_PLANNED_COUNT,
          valueGetter: getValueGetterTotalValue('atStatisticsPlannedCount'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED_DONE'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED_DONE'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
          type: ['atDataColumn'],
          columnGroupShow: 'open'
        },
        {
          colId: TOTAL_COLUMN_IDS.STATISTICS_DONE_NUMBER,
          valueGetter: getDoneNumber(TOTAL_COLUMN_IDS.STATISTICS_INVOICED_COUNT,
            TOTAL_COLUMN_IDS.STATISTICS_PRODUCTION_COUNT, TOTAL_COLUMN_IDS.STATISTICS_PLANNED_COUNT),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
          type: ['atDataColumn']
        },
        {
          colId: TOTAL_COLUMN_IDS.STATISTICS_DONE_PERCENTAGE,
          valueGetter: (valueGetterParams) => {
            const totalApprovedNumber = valueGetterParams.getValue(TOTAL_COLUMN_IDS.APPROVED_NUMBER);
            const totalDoneNumber = valueGetterParams.getValue(TOTAL_COLUMN_IDS.STATISTICS_DONE_NUMBER);
            return fixDataValue(calculateDonePercentNumber(totalApprovedNumber, totalDoneNumber));
          },
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE_PERCENT'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
          type: ['atDataColumnPercent']
        },
        {
          colId: TOTAL_COLUMN_IDS.STATISTICS_REMAINING_NUMBER,
          valueGetter: (valueGetterParams) => {
            const approvedNumber = valueGetterParams.getValue(TOTAL_COLUMN_IDS.APPROVED_NUMBER);
            const doneNumber = valueGetterParams.getValue(TOTAL_COLUMN_IDS.STATISTICS_DONE_NUMBER);
            return fixDataValue(calculateRemainsNumber(approvedNumber, doneNumber));
          },
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.REMAINS'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
          type: ['atDataColumn']
        },
        {
          colId: TOTAL_COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT,
          valueGetter: getValueGetterTotalValue('atStatisticsPotentialProductionCount'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION_DONE_POTENTIAL'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION_DONE_POTENTIAL'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
          type: ['atDataColumn'],
          columnGroupShow: 'open'
        },
        {
          colId: TOTAL_COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT,
          valueGetter: getValueGetterTotalValue('atStatisticsPotentialPlannedCount'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED_DONE_POTENTIAL'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED_DONE_POTENTIAL'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
          type: ['atDataColumn'],
          columnGroupShow: 'open'
        },
        {
          colId: TOTAL_COLUMN_IDS.STATISTICS_POTENTIAL_COUNT,
          valueGetter: getPotentialNumber(TOTAL_COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT, TOTAL_COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.POTENTIAL'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.POTENTIAL'),
          headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
          type: ['atDataColumn']
        }
      ]
    },
    ...getColumnDefinitionsForDynamicColumnList(lsi, dynamicColumnDataList)
  ];
};

const getValueGetterTotalValue = (attribute) => (valueGetterParams) => {
  return valueGetterParams.data?.modelGroupAnnualTarget?.[attribute];
};

const getColumnDefinitionsForDynamicColumnList = (lsi, dynamicColumnDataList) => {
  if (Array.isArray(dynamicColumnDataList)) {
    return dynamicColumnDataList.map((columnData, idx) => getColumnDefinitionsForDynamicColumn(lsi, columnData, (idx % 2) === 1));
  }
  return [];
};

const getColumnDefinitionsForDynamicColumn = (lsi, columnData, oddColumn) => {
  const columnId = columnData.columnId;
  const dynamicColumnIds = getColumnIdsForDynamicColumn(columnId);
  const oddClass = oddColumn ? 'ovex-DealerContractsATModelGroupViewTable-column-header-group-odd' : '';

  return {
    groupId: dynamicColumnIds.GROUP_ID,
    headerName: columnData.headerLabel,
    headerClass: ['ovex-ag-grid--th-center', oddClass],
    marryChildren: true,
    openByDefault: true,
    children: [
      {
        colId: dynamicColumnIds.DEALER_NUMBER,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atDealerNumber'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TENTATIVE'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-working'],
        hide: true,
        type: ['atDataColumn']
      },
      {
        colId: dynamicColumnIds.RECOMMENDED_NUMBER,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atRecommendedNumber'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RECOMMENDED'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-working'],
        hide: true,
        type: ['atDataColumn']
      },
      {
        colId: dynamicColumnIds.APPROVAL_NUMBER,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atApprovalNumber'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BINDING'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-working'],
        hide: true,
        type: ['atDataColumn']
      },
      {
        colId: dynamicColumnIds.APPROVED_NUMBER,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atApprovedNumber'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.APPROVED'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-approved'],
        type: ['atDataColumn']
      },
      {
        colId: dynamicColumnIds.STATISTICS_INVOICED_COUNT,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atStatisticsInvoicedCount'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.INVOICED_DONE'),
        headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.INVOICED_DONE'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
        type: ['atDataColumn'],
        columnGroupShow: 'open'
      },
      {
        colId: dynamicColumnIds.STATISTICS_PRODUCTION_COUNT,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atStatisticsProductionCount'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION_DONE'),
        headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION_DONE'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
        type: ['atDataColumn'],
        columnGroupShow: 'open'
      },
      {
        colId: dynamicColumnIds.STATISTICS_PLANNED_COUNT,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atStatisticsPlannedCount'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED_DONE'),
        headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED_DONE'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
        type: ['atDataColumn'],
        columnGroupShow: 'open'
      },
      {
        colId: dynamicColumnIds.STATISTICS_DONE_NUMBER,
        valueGetter: getDoneNumber(dynamicColumnIds.STATISTICS_INVOICED_COUNT,
          dynamicColumnIds.STATISTICS_PRODUCTION_COUNT, dynamicColumnIds.STATISTICS_PLANNED_COUNT),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
        type: ['atDataColumn']
      },
      {
        colId: dynamicColumnIds.STATISTICS_DONE_PERCENTAGE,
        valueGetter: (valueGetterParams) => {
          const approvedNumber = valueGetterParams.getValue(dynamicColumnIds.APPROVED_NUMBER);
          const doneNumber = valueGetterParams.getValue(dynamicColumnIds.STATISTICS_DONE_NUMBER);
          return fixDataValue(calculateDonePercentNumber(approvedNumber, doneNumber));
        },
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE_PERCENT'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
        type: ['atDataColumnPercent']
      },
      {
        colId: dynamicColumnIds.STATISTICS_REMAINING_NUMBER,
        valueGetter: (valueGetterParams) => {
          const approvedNumber = valueGetterParams.getValue(dynamicColumnIds.APPROVED_NUMBER);
          const doneNumber = valueGetterParams.getValue(dynamicColumnIds.STATISTICS_DONE_NUMBER);
          return fixDataValue(calculateRemainsNumber(approvedNumber, doneNumber));
        },
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.REMAINS'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
        type: ['atDataColumn']
      },
      {
        colId: dynamicColumnIds.STATISTICS_POTENTIAL_PRODUCTION_COUNT,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atStatisticsPotentialProductionCount'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION_DONE_POTENTIAL'),
        headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION_DONE_POTENTIAL'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
        type: ['atDataColumn'],
        columnGroupShow: 'open'
      },
      {
        colId: dynamicColumnIds.STATISTICS_POTENTIAL_PLANNED_COUNT,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atStatisticsPotentialPlannedCount'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED_DONE_POTENTIAL'),
        headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED_DONE_POTENTIAL'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
        type: ['atDataColumn'],
        columnGroupShow: 'open'
      },
      {
        colId: dynamicColumnIds.STATISTICS_PRODUCTION_COUNT,
        valueGetter: getPotentialNumber(dynamicColumnIds.STATISTICS_POTENTIAL_PRODUCTION_COUNT, dynamicColumnIds.STATISTICS_POTENTIAL_PLANNED_COUNT),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.POTENTIAL'),
        headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.POTENTIAL'),
        headerClass: ['ovex-DealerContractsATModelGroupViewTable-column-statistics'],
        type: ['atDataColumn']
      }
    ]
  };
};

/**
 * @param {string} columnId - key for the map of model group annual target data
 * @param {string} attributeName - attribute name in model group annual target data
 * @return {function} value getter handler
 */
const getValueGetterHandlerForDynamicColumn = (columnId, attributeName) => (valueGetterParams) => {
  const modelGroupAnnualTarget = valueGetterParams.data?.modelGroupAnnualTarget.atPeriodMap?.[columnId];
  return fixDataValue(modelGroupAnnualTarget?.[attributeName]);
};

/**
 * @param {dynamicColumnIds,TOTAL_COLUMN_IDS} atStatisticsInvoicedColumn - column id for atStatisticsInvoicedCount
 * @param {dynamicColumnIds,TOTAL_COLUMN_IDS} atStatisticsProductionColumn - column id for atStatisticsProductionCount
 * @param {dynamicColumnIds,TOTAL_COLUMN_IDS} atStatisticsPlannedColumn - column id for atStatisticsPlannedCount
 * @return {function} value getter handler
 */
const getDoneNumber = (atStatisticsInvoicedColumn, atStatisticsProductionColumn,
  atStatisticsPlannedColumn) => (valueGetterParams) => {
  const atStatisticsInvoicedCount = valueGetterParams.getValue(atStatisticsInvoicedColumn);
  const atStatisticsProductionCount = valueGetterParams.getValue(atStatisticsProductionColumn);
  const atStatisticsPlannedCount = valueGetterParams.getValue(atStatisticsPlannedColumn);
  return fixDataValue(calculateDoneNumber(atStatisticsInvoicedCount, atStatisticsProductionCount, atStatisticsPlannedCount));
};
/**
 * @param {dynamicColumnIds,TOTAL_COLUMN_IDS} atStatisticsPotentialProductionColumn - column id for atStatisticsPotentialProductionCount
 * @param {dynamicColumnIds,TOTAL_COLUMN_IDS} atStatisticsPotentialPlannedColumn - column id for atStatisticsPotentialPlannedCount
 * @return {function} value getter handler
 */
const getPotentialNumber = (atStatisticsPotentialProductionColumn, atStatisticsPotentialPlannedColumn) => (valueGetterParams) => {
  const atStatisticsPotentialProductionCount = valueGetterParams.getValue(atStatisticsPotentialProductionColumn);
  const atStatisticsPotentialPlannedCount = valueGetterParams.getValue(atStatisticsPotentialPlannedColumn);
  return fixDataValue(calculatePotentialNumber(atStatisticsPotentialProductionCount, atStatisticsPotentialPlannedCount));
};
