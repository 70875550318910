import PropTypes from 'prop-types';
import React from 'react';
import { ColumnDataDTO, ContractsATModelGroupsViewDTO } from '@ovex/annual-target-web-api';

import { GROUP_DISPLAY_TYPE, OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';

import {
  autoGroupColumnDef,
  columnTypes,
  getColumnDefinitions
} from './DealerContractsATModelGroupViewTable.columnDefs';

import './DealerContractsATModelGroupViewTable.scss';

const propTypes = {
  contractATModelGroupsViewList: PropTypes.arrayOf(PropTypes.instanceOf(ContractsATModelGroupsViewDTO)),
  dynamicColumnDataList: PropTypes.arrayOf(PropTypes.instanceOf(ColumnDataDTO))
};

const defaultProps = {
  contractATModelGroupsViewList: null,
  dynamicColumnDataList: null
};

const DealerContractsATModelGroupViewTable = React.memo(React.forwardRef((props, ref) => {
  const lsi = React.useContext(LsiContext);

  const columnDefs = React.useMemo(
    () => getColumnDefinitions(lsi, props.dynamicColumnDataList),
    [lsi, props.dynamicColumnDataList]
  );

  const handleColumnGroupOpened = React.useCallback(
    (columnGroupOpenedEvent) => {
      const expanded = columnGroupOpenedEvent.columnGroup.isExpanded();
      const { columnDefs } = columnGroupOpenedEvent.columnApi.columnModel;
      const groupIDs = columnDefs.filter(column => column.hasOwnProperty('groupId')).map(column => column.groupId);
      groupIDs.forEach((groupId) => {
        columnGroupOpenedEvent.columnApi.setColumnGroupOpened(groupId, expanded);
      });

    },
    []
  );
  const onFirstDataRendered = (params) => {
    const autoColIdList = params.columnApi.columnModel.groupAutoColumns.map(col => col.colId);
    params.columnApi.autoSizeColumns(autoColIdList);
  };

  return (
    <OvexAGTable
      autoGroupColumnDef={autoGroupColumnDef}
      className='ovex-DealerContractsATModelGroupViewTable'
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      enableRangeSelection
      groupDefaultExpanded={1}
      groupDisplayType={GROUP_DISPLAY_TYPE.multipleColumns}
      height='550px'
      onColumnGroupOpened={handleColumnGroupOpened}
      onFirstDataRendered={onFirstDataRendered}
      onRowDataChanged={handleRowDataChanged}
      ref={ref}
      rowData={props.contractATModelGroupsViewList}
      rowSelection='multiple'
      showOpenedGroup
    />
  );
}));

const handleRowDataChanged = (params) => {
  const autoColIdList = params.columnApi.columnModel.groupAutoColumns.map(col => col.colId);
  params.columnApi.autoSizeColumns(autoColIdList);
};

DealerContractsATModelGroupViewTable.propTypes = propTypes;
DealerContractsATModelGroupViewTable.defaultProps = defaultProps;

export default DealerContractsATModelGroupViewTable;
