import {
  handleEditableApprovalNumberColumn,
  handleEditableDealerNumberColumn,
  handleInnerRendererTOTAL,
  handleNumberValueFormatter,
  handleValueParser,
  handleValueSetter
} from '../utils/helpers';
import {
  COLUMN_GROUP_IDS,
  COLUMN_IDS,
  tableBaseColumnDefs,
  tableBaseColumnGroupDefs
} from '../utils/tableBaseColumnDefs';
import { ClientSideRowModelSteps, EXCEL_STYLES_IDS } from '../../../../common/components/ag-grid';
import { handleDonePercentNumberAggFunc, handleDonePercentNumberValueGetter } from '../../../utils/statisticsHelpers';
import { quarterPeriodFormatter, simpleNullishValueFormatter } from '../../../../common/utils/formatters';
import { sumNullableAccumulator } from '../../../utils/helpers';

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn',
  DATA_CD: 'dataDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  },
  [COLUMN_TYPE_ENUM.DATA_CD]: {
    cellClass: ['ovex-DealerPeriodAnnualTargetSalesTable-cell-numeric'],
    headerClass: ['ovex-ag-grid--th-center'],
    valueFormatter: handleNumberValueFormatter,
    aggFunc: 'sum'
  }
};

export const getAutoGroupColumnDef = (lsi) => {
  return {
    width: tableBaseColumnDefs[COLUMN_IDS.BRANCH_DEALER_NUMBER].width,
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BRANCH_PERIOD_RCP'),
    valueGetter: 'data && data.periodOrder',
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    valueFormatter: (valueFormatterParams) => quarterPeriodFormatter(valueFormatterParams.value),
    cellRendererParams: {
      innerRenderer: handleInnerRendererTOTAL,
      suppressCount: true
    },
    pinned: 'left'
  };
};

const getPeriodSumHandle = (attribute) => (params) => {
  const { node, data, api } = params;
  if (node.group) { return null;}
  if (data.isTotalRow()) {
    let sumValue = null;
    api.forEachLeafNode(rowNode => {
      if (data.periodOrder === rowNode.data.periodOrder) {
        sumValue = sumNullableAccumulator(sumValue, rowNode.data[attribute]);
      }
    });
    return sumValue;
  } else { return data[attribute];}
};

const getPeriodSumDealerNumber = getPeriodSumHandle('atsDealerNumber');
const getPeriodSumRecommendedNumber = getPeriodSumHandle('atsRecommendedNumber');
const getPeriodSumApprovalNumber = getPeriodSumHandle('atsApprovalNumber');
const getPeriodSumApprovedNumber = getPeriodSumHandle('atsApprovedNumber');
const getPeriodSumDoneNumber = getPeriodSumHandle('atsStatisticsSoldCount');
const getPeriodSumRemainsNumber = getPeriodSumHandle('atsStatisticsRemainsCount');

const handleValueSetterWithRefreshAgg = (setterName) => (valueSetterParams) => {
  const dataChanged = handleValueSetter(setterName)(valueSetterParams);
  dataChanged && valueSetterParams.api.refreshClientSideRowModel(ClientSideRowModelSteps.AGGREGATE);
  return dataChanged;
};

export const getColumnDefinitions = (lsi, editableDealerNumberColumn, editableApprovalNumberColumn) => {
  return [
    {
      field: 'groupValue',
      valueGetter: 'data && data.groupValue',
      rowGroup: true,
      hide: true
    },
    {
      ...tableBaseColumnDefs[COLUMN_IDS.BRANCH_DEALER_NUMBER],
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BRANCH_PERIOD_RCP'),
      valueGetter: 'data && data.branchDealerNumber',
      cellClass: EXCEL_STYLES_IDS.STRING,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      hide: true
    },
    {
      ...tableBaseColumnDefs[COLUMN_IDS.PERIOD],
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PERIOD'),
      valueGetter: 'data && data.periodOrder',
      valueFormatter: 'value != null ? value + "." : ""',
      cellClass: ['ovex-DealerPeriodAnnualTargetSalesTable-cell-period', EXCEL_STYLES_IDS.STRING],
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      sortable: false,
      hide: true
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_WORKING_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_DEALER_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TENTATIVE'),
          valueGetter: getPeriodSumDealerNumber,
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
          cellClassRules: {
            'ovex-DealerPeriodAnnualTargetSalesTable-cell-numeric--editable': params => handleEditableDealerNumberColumn(params, editableDealerNumberColumn),
            'mdi': params => handleEditableDealerNumberColumn(params, editableDealerNumberColumn),
            'ovex-DealerPeriodAnnualTargetSalesTable-cell-numeric--modified': (cellClassParams) => !cellClassParams.node.group && cellClassParams.data.isATSDealerNumberModified()
          },
          editable: params => handleEditableDealerNumberColumn(params, editableDealerNumberColumn),
          valueParser: handleValueParser,
          valueSetter: handleValueSetterWithRefreshAgg('setATSDealerNumber')
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_RECOMMENDED_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RECOMMENDED'),
          valueGetter: getPeriodSumRecommendedNumber,
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
          valueFormatter: (valueFormatterParams) => simpleNullishValueFormatter(valueFormatterParams.value, '-')
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_APPROVAL_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BINDING'),
          valueGetter: getPeriodSumApprovalNumber,
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
          cellClassRules: {
            'ovex-DealerPeriodAnnualTargetSalesTable-cell-numeric--editable': params => handleEditableApprovalNumberColumn(params, editableApprovalNumberColumn),
            'mdi': params => handleEditableApprovalNumberColumn(params, editableApprovalNumberColumn),
            'ovex-DealerPeriodAnnualTargetSalesTable-cell-numeric--modified': (cellClassParams) => !cellClassParams.node.group && cellClassParams.data.isATSApprovalNumberModified()
          },
          editable: params => handleEditableApprovalNumberColumn(params, editableApprovalNumberColumn),
          valueParser: handleValueParser,
          valueSetter: handleValueSetterWithRefreshAgg('setATSApprovalNumber')
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_APPROVED_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_APPROVED_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.APPROVED'),
          valueGetter: getPeriodSumApprovedNumber,
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_DONE_GROUP],
      headerClass: [],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_INVOICED_COUNT],
          headerClass: [],
          menuTabs: []
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_PRODUCTION_COUNT],
          headerClass: [],
          menuTabs: []
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_PLANNED_COUNT],
          headerClass: [],
          menuTabs: []
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_DONE_NUMBER],
          valueGetter: getPeriodSumDoneNumber,
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_DONE_PERCENT_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_DONE_PERCENTAGE],
          valueGetter: (valueGetterParams) => {
            if (valueGetterParams.data.isTotalRow()) {
              const atsApprovedNumber = valueGetterParams.getValue(COLUMN_IDS.AT_ATS_APPROVED_NUMBER);
              const atsStatisticsSoldCount = valueGetterParams.getValue(COLUMN_IDS.STATISTICS_DONE_NUMBER);
              return handleDonePercentNumberValueGetter(valueGetterParams, atsApprovedNumber, atsStatisticsSoldCount);
            }
            return handleDonePercentNumberValueGetter(valueGetterParams, valueGetterParams.data.atsApprovedNumber, valueGetterParams.data.atsStatisticsSoldCount);

          },
          valueFormatter: null,
          aggFunc: handleDonePercentNumberAggFunc,
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE_PERCENT'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_REMAINING_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_REMAINING_NUMBER],
          valueGetter: getPeriodSumRemainsNumber,
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.REMAINS'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_GROUP],
      headerName: [],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT],
          headerClass: [],
          menuTabs: []
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT],
          headerClass: [],
          menuTabs: []
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_COUNT],
          headerClass: [],
          menuTabs: []
        }
      ]
    }
  ];
};
