import {
  atPeriodCorrectionValueFormatter,
  atPeriodCorrectionValueGetter,
  atPeriodValueGetter,
  atSalesPeriodValueGetter,
  handleContractTypeValueFormatter,
  handleNegotiationStatusValueFormatter,
  periodHeaderValueGetter,
  proposalApprovalValueFormatter,
  proposalDealerValueFormatter
} from './NegotiationsTable.utils';
import ATPeriodRenderer from './ATPeriodRenderer/ATPeriodRenderer';
import ATSalesPeriodRenderer from './ATSalesPeriodRenderer/ATSalesPeriodRenderer';

const COLUMN_IDS = {
  YEAR: 'year',
  TYPE: 'type',
  NAME: 'name',
  STATUS: 'status',
  VERSION: 'version',
  DEALER_COUNT: 'dealerCount',
  MODEL_GROUP_COUNT: 'modelGroupCount',
  TEXT_COUNT: 'textCount',
  PROPOSAL_DEALER: 'proposalDealer',
  PROPOSAL_APPROVAL: 'proposalApproval',
  COEFFICIENT: 'coefficient',
  AT_PERIOD_1: 'atPeriod_1',
  AT_PERIOD_2: 'atPeriod_2',
  AT_PERIOD_3: 'atPeriod_3',
  AT_PERIOD_4: 'atPeriod_4',
  AT_PERIOD_CORRECTION: 'atPeriodCorrection',
  AT_SALES_PERIOD_1: 'atSalesPeriod_1',
  AT_SALES_PERIOD_2: 'atSalesPeriod_2',
  AT_SALES_PERIOD_3: 'atSalesPeriod_3',
  AT_SALES_PERIOD_4: 'atSalesPeriod_4'
};

const GROUP_COLUMN_IDS = {
  NEGOTIATION: 'negotiation',
  AT_PERIOD: 'atPeriod',
  AT_SALES_PERIOD: 'atSalesPeriod'
};

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn',
  PERIOD_CD: 'periodDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  },
  [COLUMN_TYPE_ENUM.PERIOD_CD]: {
    headerValueGetter: periodHeaderValueGetter,
    width: 80,
    menuTabs: [],
    suppressMovable: true
  }
};

export const getColumnDefinitions = (lsi) => {
  return [
    {
      groupId: GROUP_COLUMN_IDS.NEGOTIATION,
      headerName: '',
      marryChildren: true,
      children: [
        {
          colId: COLUMN_IDS.YEAR,
          valueGetter: 'data && data.year',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.YEAR'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
          width: 80,
          rowGroup: true
        },
        {
          colId: COLUMN_IDS.TYPE,
          valueGetter: 'data && data.negotiationType',
          valueFormatter: handleContractTypeValueFormatter(lsi),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.NEGOTIATION'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
          width: 125
        },
        {
          colId: COLUMN_IDS.NAME,
          valueGetter: 'data && data.name',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.NAME'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
          flex: 1,
          minWidth: 180
        },
        {
          colId: COLUMN_IDS.STATUS,
          valueGetter: 'data && data.status',
          valueFormatter: handleNegotiationStatusValueFormatter(lsi),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.STATUS'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
          width: 120
        },
        {
          colId: COLUMN_IDS.VERSION,
          valueGetter: 'data && data.version',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.VERSION'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
          width: 80
        },
        {
          colId: COLUMN_IDS.DEALER_COUNT,
          valueGetter: 'data && data.dealerList',
          valueFormatter: 'value && value.length',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DEALERS'),
          width: 110
        },
        {
          colId: COLUMN_IDS.MODEL_GROUP_COUNT,
          valueGetter: 'data && data.modelGroupList',
          valueFormatter: 'value && value.length',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODELS'),
          width: 90
        },
        {
          colId: COLUMN_IDS.TEXT_COUNT,
          valueGetter: 'data && data.textList',
          valueFormatter: 'value && value.length',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TEXTS'),
          width: 90,
          hide: true
        },
        {
          colId: COLUMN_IDS.PROPOSAL_DEALER,
          valueFormatter: proposalDealerValueFormatter,
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PROPOSAL_DEALER_SETTINGS'),
          width: 208
        },
        {
          colId: COLUMN_IDS.PROPOSAL_APPROVAL,
          valueFormatter: proposalApprovalValueFormatter,
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PROPOSAL_APPROVAL_SETTINGS'),
          width: 176
        },
        {
          colId: COLUMN_IDS.COEFFICIENT,
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.COEFFICIENT'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COEFFICIENT_RCP_ROC'),
          width: 85,
          valueGetter: (params) => {
            return (params?.data?.coefficientFrom != null ? params.data.coefficientFrom.toFixed(2) : '') +
              (params?.data?.coefficientTo != null ? ` - ${params.data.coefficientTo.toFixed(2)}` : '');
          },
          menuTabs: []
        }
      ]
    },
    {
      groupId: GROUP_COLUMN_IDS.AT_PERIOD,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.ROC'),
      marryChildren: true,
      children: [
        {
          colId: COLUMN_IDS.AT_PERIOD_1,
          valueGetter: atPeriodValueGetter,
          cellRenderer: ATPeriodRenderer,
          type: [COLUMN_TYPE_ENUM.PERIOD_CD],
          width: 40,
          ovexUnitOrder: 1
        },
        {
          colId: COLUMN_IDS.AT_PERIOD_2,
          valueGetter: atPeriodValueGetter,
          cellRenderer: ATPeriodRenderer,
          type: [COLUMN_TYPE_ENUM.PERIOD_CD],
          width: 40,
          ovexUnitOrder: 2
        },
        {
          colId: COLUMN_IDS.AT_PERIOD_3,
          valueGetter: atPeriodValueGetter,
          cellRenderer: ATPeriodRenderer,
          type: [COLUMN_TYPE_ENUM.PERIOD_CD],
          width: 40,
          ovexUnitOrder: 3
        },
        {
          colId: COLUMN_IDS.AT_PERIOD_4,
          valueGetter: atPeriodValueGetter,
          cellRenderer: ATPeriodRenderer,
          type: [COLUMN_TYPE_ENUM.PERIOD_CD],
          width: 40,
          ovexUnitOrder: 4
        },
        {
          colId: COLUMN_IDS.AT_PERIOD_CORRECTION,
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PERIOD_CORRECTION'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.PERIOD_CORRECTION'),
          valueGetter: atPeriodCorrectionValueGetter,
          valueFormatter: atPeriodCorrectionValueFormatter,
          width: 56,
          menuTabs: [],
          suppressMovable: true
        }
      ]
    },
    {
      groupId: GROUP_COLUMN_IDS.AT_SALES_PERIOD,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RCP'),
      marryChildren: true,
      children: [
        {
          colId: COLUMN_IDS.AT_SALES_PERIOD_1,
          valueGetter: atSalesPeriodValueGetter,
          cellRenderer: ATSalesPeriodRenderer,
          type: [COLUMN_TYPE_ENUM.PERIOD_CD],
          ovexUnitOrder: 1
        },
        {
          colId: COLUMN_IDS.AT_SALES_PERIOD_2,
          valueGetter: atSalesPeriodValueGetter,
          cellRenderer: ATSalesPeriodRenderer,
          type: [COLUMN_TYPE_ENUM.PERIOD_CD],
          ovexUnitOrder: 2
        },
        {
          colId: COLUMN_IDS.AT_SALES_PERIOD_3,
          valueGetter: atSalesPeriodValueGetter,
          cellRenderer: ATSalesPeriodRenderer,
          type: [COLUMN_TYPE_ENUM.PERIOD_CD],
          ovexUnitOrder: 3
        },
        {
          colId: COLUMN_IDS.AT_SALES_PERIOD_4,
          valueGetter: atSalesPeriodValueGetter,
          cellRenderer: ATSalesPeriodRenderer,
          type: [COLUMN_TYPE_ENUM.PERIOD_CD],
          ovexUnitOrder: 4
        }
      ]
    }
  ];
};
